import React from 'react'

import { Box, styled, Checkbox, Typography, FormControlLabel } from '@mui/material'
import PropTypes from 'prop-types'
import { breakPoints } from '@constants/styles'

const Wrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2),
  top: theme.spacing(4),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '.subtitle': {
    display: 'inline-block',
    marginRight: theme.spacing(2),
    textTransform: 'uppercase',
    fontWeight: 600,
  },

  [theme.breakpoints.down('md')]: {
    position: 'static',
    justifyContent: 'start',
    flexWrap: 'wrap',
    marginTop: theme.spacing(1),
    borderTop: 'none',
    marginBottom: '32px',
  },
}))

const StyledFilterWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap',
    '& > *': {
      width: '40%',
      marginLeft: '0 !important',
    },
  },
}))

/**
 * @typedef {import('./hooks').StoreFilter} StoreFilter
 */

/**
 * Store filters component
 * @param {{ filters: StoreFilter }} props
 * @returns
 */
const StoreFilters = ({ filters }) => {
  const { state, actions } = filters
  return (
    <Wrapper>
      <Typography className="subtitle">Store Types:</Typography>
      <StyledFilterWrapper>
        <FormControlLabel
          control={<Checkbox checked={state.showroom} onChange={actions.showroom} />}
          label="Showrooms"
        />
        <FormControlLabel control={<Checkbox checked={state.kids} onChange={actions.kids} />} label="Kids" />
        <FormControlLabel control={<Checkbox checked={state.patio} onChange={actions.patio} />} label="Patio" />
        <FormControlLabel control={<Checkbox checked={state.outlet} onChange={actions.outlet} />} label="Outlet" />
      </StyledFilterWrapper>
    </Wrapper>
  )
}

StoreFilters.propTypes = {
  filters: PropTypes.object.isRequired,
}

export default StoreFilters
