import React from 'react'

import { Box, Typography, styled } from '@mui/material'

import bgImage from '@assets/images/store-locator-bg.png'

const Wrapper = styled(Box)`
  padding: ${p => p.theme.spacing(2)};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 10rem;
  background-image: url(${bgImage});
  flex-direction: column;
  margin-top: ${p => p.theme.spacing(1)};
  background-position: center;
`

const BannerText = styled(Typography)`
  color: #fff;
  font-weight: 400;
`

const StoreLocatorBanner = () => (
  <Wrapper>
    <BannerText variant="h1">ROOMS TO GO STORE LOCATOR</BannerText>
    <BannerText variant="subtitle1">Enter city, state, or zip to shop top quality furniture in your area.</BannerText>
  </Wrapper>
)

export default StoreLocatorBanner
