// dependencies
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { arrayOf, number, object, shape, string, func } from 'prop-types'
import { Paper, useTheme, styled, useMediaQuery, Button } from '@mui/material'
import _isEqual from 'lodash/isEqual'
// helpers
import { useMyStore } from '@hooks/useMyStore'
import { loadMore } from '@helpers/array-helper'
import { getMarkers, groupStoresByAddress } from '@helpers/store-locator'
import useStoreLocatorContext from '@context/storeLocatorContext/useStoreLocatorContext'
// components
import StoreSearchBar from './search-bar'
import MapLocationsList from './map-locations-list'
import WarningMessage from './warning-message'
import RadarMap from './RadarMap/RadarMap'

import '../../assets/css/components/store-locator/store-locator.sass'

const STORES_MAP_LIST_LIMIT = 26

const StyledPaperContainer = styled(Paper, {
  shouldForwardProp: prop => prop !== 'isMobile',
})(({ isMobile }) => ({
  ...(isMobile && { padding: '0 !important' }),
}))

const StoreListWrapper = styled('div')(({ theme }) => ({
  // display: 'flex',
  display: 'grid',
  gridTemplateColumns: '425px 1fr',
  gridTemplateRows: '1fr',
  background: '#ffffff',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
    gridTemplateRows: '60vw 1fr',
  },
}))

const RadarMapWrapper = styled('div')(({ theme }) => ({
  gridColumn: '2 / 3',
  gridRow: '1 / 2',
  [theme.breakpoints.down('md')]: {
    gridColumn: '1 / 2',
    gridRow: '1 / 2',
  },
}))

const StoresListContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingRight: 0,
  gridColumn: '1 / 2',
  gridRow: '1 / 2',
  [theme.breakpoints.down('md')]: {
    gridColumn: '1 / 2',
    gridRow: '2 / 3',
  },
}))

const StyledLoadMoreButton = styled(Button)(() => ({
  width: '90%',
  marginTop: '1rem !important',
  alignSelf: 'center',
}))

const StyledStoreSearchBar = styled(StoreSearchBar)(({ theme }) => ({
  zIndex: 5,
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
  display: 'flex',
  flexDirection: 'column',
  '&.mobile': {
    display: 'none',
    backgroundColor: theme.palette.common.white,
    margin: '0px -15px 10px -15px',
    padding: '10px 15px',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.10)',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      width: 'auto',
    },
  },
}))

const StoreMap = ({ storeLocations }) => {
  const stores = useStoreLocatorContext(state => state.stores)
  const setStores = useStoreLocatorContext(state => state.setStores)
  const { myStore } = useMyStore()
  const myStoreInContext = useStoreLocatorContext(state => state.myStore)
  const setMyStoreInContext = useStoreLocatorContext(state => state.setMyStore)
  const currentPosition = useStoreLocatorContext(state => state.currentPosition)
  const [pageNumber, setPageNumber] = useState(1)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  // myStore updates frequently in Gatsby's state, which results in excessive rendering and buggy behavior
  // in the Radar Map.  This use effect utilizes "myStoreInContext", which only triggers recalculations
  // when the myStore values actually change.
  useEffect(() => {
    if (!_isEqual(myStoreInContext, myStore) && !!myStore?.storeName) {
      setMyStoreInContext(myStore)
    }
  }, [myStore, myStoreInContext, setMyStoreInContext])

  const markers = useMemo(
    () => (currentPosition.lat ? getMarkers(currentPosition.lat, currentPosition.lng, storeLocations) : []),
    [storeLocations, currentPosition],
  )
  const paginatedMarkers = useMemo(() => {
    if (!isMobile) return markers
    return loadMore(markers, pageNumber)
  }, [isMobile, markers, pageNumber])

  useEffect(() => {
    const tempGroupedStores = groupStoresByAddress(paginatedMarkers)?.slice(0, STORES_MAP_LIST_LIMIT)
    setStores(tempGroupedStores)
  }, [paginatedMarkers, setStores])

  return (
    <>
      <StyledStoreSearchBar id="header-find-stores" className="mobile" />
      <StyledPaperContainer sx={{ p: 4, position: 'relative' }} isMobile={isMobile}>
        <StyledStoreSearchBar id="header-find-stores" />
        <StoreListWrapper>
          <StoresListContainer>
            {markers[0]?.distance > 100 ? (
              <WarningMessage isMobile={isMobile} />
            ) : (
              <>
                <MapLocationsList />
                {isMobile && stores.length < markers.length && (
                  <StyledLoadMoreButton
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    sx={{ mt: 1, mb: 2 }}
                    value="View more"
                    gtm-category="view more stores"
                    gtm-action="view more stores"
                    onClick={() => setPageNumber(prevPageNumber => prevPageNumber + 1)}
                  >
                    VIEW MORE
                  </StyledLoadMoreButton>
                )}
              </>
            )}
          </StoresListContainer>
          <RadarMapWrapper>
            <RadarMap variant="store-locator" includeCurrentMarker />
          </RadarMapWrapper>
        </StoreListWrapper>
      </StyledPaperContainer>
    </>
  )
}

StoreMap.propTypes = {
  storeLocations: arrayOf(
    shape({
      Address1: string,
      Address2: string,
      City: string,
      Location: shape({
        latLng: shape({
          lat: number,
          lng: number,
        }),
      }),
      PhoneNumber: string,
      State: string,
      StoreHours: object,
      StoreName: string,
      StoreNumber: number,
      StoreType: string,
      Zip: string,
      distance: number,
      id: string,
      slug: string,
    }),
  ),
}

export default StoreMap
