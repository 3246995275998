import React from 'react'
import PropTypes from 'prop-types'
import { Paper, styled } from '@mui/material'

import Tabs from './Tabs'
import { useStoreFilters } from './hooks'

const StyledPaper = styled(Paper)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    padding: '10px',
  },
}))

const SortedStoreList = ({ staticData }) => {
  const filters = useStoreFilters()

  return (
    <StyledPaper sx={{ p: 4, position: 'relative' }}>
      <Tabs filters={filters} staticData={staticData} />
    </StyledPaper>
  )
}

SortedStoreList.propTypes = {
  staticData: PropTypes.array.isRequired,
}
export default SortedStoreList
