import React, { useState, useEffect } from 'react'
import { useLocation } from '@gatsbyjs/reach-router'
import { useApolloClient } from '@apollo/client'
import { keyboardBlur } from '@helpers/input-helper'
import { Button, TextField, InputAdornment, useTheme, useMediaQuery, styled } from '@mui/material'
import { getLatLng } from '@helpers/geo-location'
import useStoreLocatorContext from '@context/storeLocatorContext/useStoreLocatorContext'
import { getURLParam } from '@helpers/general'

const propOptions = { shouldForwardProp: prop => prop !== 'isMobile' }
const StyledContainerSearch = styled(
  'div',
  propOptions,
)(({ isMobile }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: '1rem',
  ...(!isMobile ? { width: '450px', padding: '0 0.5rem' } : { margin: '0 1rem 1rem 1rem' }),
}))

const StyledSearchButton = styled(
  Button,
  propOptions,
)(({ isMobile }) => ({
  marginTop: '0!important',
  height: '2rem',
  ...(isMobile && {
    fontSize: '0.8rem',
  }),
}))

const StyledInputSearch = styled(TextField)(() => ({
  border: 'none',
  input: {
    padding: '10px 15px !important',
  },
}))

const onEnterPress = (e, changeAddress) => {
  if (e.keyCode === 13 && e.shiftKey === false) {
    changeAddress()
    keyboardBlur(e)
  }
}

const SearchBar = ({ ...restProps }) => {
  const [invalidAddress, setInvalidAddress] = useState(false)
  const [addressInput, setAddressInput] = useState('')
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const mapInstance = useStoreLocatorContext(state => state.mapInstance)
  const setCurrentPosition = useStoreLocatorContext(state => state.setCurrentPosition)
  const setSearchedLocation = useStoreLocatorContext(state => state.setSearchedLocation)
  const nativeLocation = useLocation()
  const apolloClient = useApolloClient()

  const updateInputAddressValue = evt => {
    setAddressInput(evt.target.value)
    setInvalidAddress(false)
  }

  const changeAddress = async defaultAddress => {
    const addressData = defaultAddress || addressInput
    const location = await getLatLng(apolloClient, addressData)
    if (location?.lat) {
      setSearchedLocation(location)
      mapInstance.setCenter({ lat: location.lat, lng: location.lng })
      setCurrentPosition({ lat: location.lat, lng: location.lng })
    } else {
      setInvalidAddress(true)
    }
  }

  useEffect(() => {
    if (!mapInstance?.loaded) return
    const zipQueryParam = getURLParam('zip')
    if (zipQueryParam) {
      setAddressInput(zipQueryParam)
      setInvalidAddress(false)
      changeAddress(zipQueryParam)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nativeLocation, mapInstance])

  return (
    <StyledContainerSearch {...restProps} isMobile={isMobile}>
      {invalidAddress && <p className="invalid-message">*Please enter a valid Address</p>}
      <StyledInputSearch
        tabIndex={0}
        label={isMobile ? 'City, State or ZIP' : 'Enter City, State or ZIP Code'}
        size="small"
        fullWidth
        variant="outlined"
        data-testid="search-store-locator"
        name="search-store-locator"
        value={addressInput}
        onChange={evt => updateInputAddressValue(evt)}
        onKeyDown={e => onEnterPress(e, changeAddress)}
        autoComplete="off"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <StyledSearchButton
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                sx={{ mt: 1 }}
                value="Change address"
                gtm-category="see in store"
                gtm-action="change address"
                onClick={() => changeAddress()}
                isMobile={isMobile}
              >
                FIND STORES
              </StyledSearchButton>
            </InputAdornment>
          ),
        }}
      />
    </StyledContainerSearch>
  )
}

SearchBar.propTypes = {}

export default SearchBar
