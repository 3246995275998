import React, { useState, useEffect } from 'react'

import { Grid, styled } from '@mui/material'
import { arrayOf, object, shape, oneOfType, array } from 'prop-types'
import { setupAnalytics } from '@helpers/google-tag-manager'
import { StoreLocatorProvider } from '@context/storeLocatorContext/provider'
import Breadcrumbs from '@templates/strapi-cms/content-types/Breadcrumbs'
import StoreLocatorBanner from './StoreLocatorBanner'
import StoreMap from './store-map'
import SortedStoreList from './sorted-store-list'

const breadcrumbData = [{ label: 'Rooms To Go Store Locator', level: 1, category: 'stores' }]

const StoreLocatorBannerContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

const StoreLocator = ({ data, staticData }) => {
  useEffect(() => {
    setupAnalytics({ pageData: { type: 'store', title: 'Store locator', path: '/stores' } })
  }, [])

  return (
    <StoreLocatorProvider>
      <Breadcrumbs productBreadcrumbs={breadcrumbData} />
      <Grid container spacing={2}>
        <StoreLocatorBannerContainer item xs={12}>
          <StoreLocatorBanner />
        </StoreLocatorBannerContainer>
        <Grid item xs={12}>
          <StoreMap storeLocations={data.stores} />
        </Grid>
        <Grid item xs={12}>
          <SortedStoreList staticData={staticData} />
        </Grid>
      </Grid>
    </StoreLocatorProvider>
  )
}

StoreLocator.propTypes = {
  data: shape({
    stores: arrayOf(object),
  }),
  staticData: oneOfType([object.isRequired, array.isRequired]),
}

export default StoreLocator
