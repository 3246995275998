import React, { useState, useRef, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { arrayOf, object, func, string, bool } from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { useTheme, useMediaQuery, styled } from '@mui/material'
import { letters } from '@helpers/string-helper'
import Stack from '@mui/material/Stack'
import useStoreLocatorContext from '@context/storeLocatorContext/useStoreLocatorContext'
import '../../assets/css/components/store-locator/map-locations-list.sass'
import InStoreScheduleModal from '@shared/modals/InStoreSchedule'
import StoreListDetail from '../StoreListDetail/StoreListDetail'
import { getSpecialClosings } from './helpers'

const LocationsContainer = styled(Stack, {
  shouldForwardProp: prop => prop !== 'isMobile',
})(({ isMobile }) => ({
  display: 'flex',
  borderBottom: '1px solid #cccccc',
  borderTop: '1px solid #cccccc',
  flexDirection: 'column',
  contentVisibility: 'auto',
  ...(!isMobile && {
    overflow: 'auto',
    height: 'calc(60vh - 60px)',
    maxHeight: '60vh',
  }),
  padding: 0,
  ...(isMobile && {}),
}))

const scrollToSelectedMarker = refItem =>
  typeof refItem !== 'undefined'
    ? refItem?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
    : null

const StoreWrapper = styled('div')(({ selected }) => ({
  cursor: 'pointer',
  margin: 15,
  '& > div': {
    ...(selected && { backgroundColor: '#d9e5f1 !important' }),
  },
}))

const MapLocationList = ({ showRatings }) => {
  const stores = useStoreLocatorContext(state => state.stores)
  const selectStore = useStoreLocatorContext(state => state.selectStore)
  const selectedStore = useStoreLocatorContext(state => state.selectedStore)
  const refSelectedMarker = useRef()
  const data = useStaticQuery(query)
  const [scheduleModalData, setScheduleModalData] = useState({ show: false, store: null })

  const handleShowroomClick = store => {
    setScheduleModalData({ show: true, store })
  }

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    if (selectedStore && refSelectedMarker.current && !isMobile) {
      scrollToSelectedMarker(refSelectedMarker)
    }
  }, [selectedStore, isMobile])

  const closeModal = () => setScheduleModalData({ show: false, store: null })

  return (
    <LocationsContainer isMobile={isMobile} padding={2}>
      {stores?.map((mark, index) => {
        const storeDetails = {
          city: mark?.city,
          distance: mark?.distance,
          hours: mark?.hours,
          name: mark?.name,
          phone: mark?.phone?.replace('/', '-') || mark?.phoneNumber?.replace('/', '-'),
          selectedRatings: mark?.ratings?.lifetime || {},
          state: mark?.state,
          storeNumber: mark.storeNumber ? `${mark.storeNumber}` : null,
          title: mark?.title,
          types: mark?.types || [],
          zip: mark?.zip,
        }

        const specialClosings = getSpecialClosings({ data, mark })
        // const markerIsSelected = !isEmpty(selectedMarker) && selectedMarker?.storeNumber === mark?.storeNumber
        const markerIsSelected = !isEmpty(selectedStore) && selectedStore?.StoreNumber === mark?.storeNumber

        return (
          <StoreWrapper
            {...(markerIsSelected && { ref: refSelectedMarker })}
            selected={markerIsSelected}
            role={isMobile ? null : 'button'}
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex={isMobile ? null : '0'}
            onClick={isMobile ? null : () => selectStore(mark.StoreNumber)}
            onKeyDown={
              isMobile
                ? null
                : e => {
                    if (e.keyCode === 13) selectStore(mark.StoreNumber)
                  }
            }
            key={`${mark.title}|${mark.name}`}
          >
            <StoreListDetail
              handleShowroomClick={() => handleShowroomClick(mark)}
              isSelected={markerIsSelected}
              letter={letters[index]}
              specialClosings={specialClosings}
              showRatings={!!showRatings}
              storeDetails={storeDetails}
            />
          </StoreWrapper>
        )
      })}

      {scheduleModalData.store && scheduleModalData.show && (
        <InStoreScheduleModal
          shouldShowModal={scheduleModalData.show}
          store={{
            ...scheduleModalData.store,
            storeNumber: scheduleModalData.store.StoreNumber || scheduleModalData.store.storeNumber,
            StoreNumber: scheduleModalData.store.StoreNumber || scheduleModalData.store.storeNumber,
          }}
          closeModal={closeModal}
          changeLocation={closeModal}
        />
      )}
    </LocationsContainer>
  )
}

MapLocationList.propTypes = {
  stores: arrayOf(object),
  lia: string,
  selectedMarker: object,
  showRatings: bool,
}

const query = graphql`
  query StoresClosingsQuery {
    allStrapiStore(filter: { strapi_id: { ne: 0 } }) {
      edges {
        node {
          id
          InStoreAppointments
          StoreNumber
          SpecialClosings {
            Markdown {
              Markdown {
                data {
                  childMarkdownRemark {
                    html
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default MapLocationList
