import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Tabs as MuiTabs, Tab, Box, Typography, CircularProgress, styled } from '@mui/material'
import ChevronRight from '@mui/icons-material/ChevronRight'
import useStoreLocatorContext from '@context/storeLocatorContext/useStoreLocatorContext'

import { toID } from '@helpers/store-locator'
import { getCurrentLocation } from '@helpers/geo-location'
import TabPanel from './TabPanel'
import StoreFilters from './StoreFilters'
import MobileStoreSelector from './MobileStoreSelector'
import { useSortedStores } from './hooks'

const Tabs = styled(MuiTabs)(({ theme }) => ({
  borderRight: `solid 1px ${p => p.theme.palette.divider}`,
  overflow: 'visible',
  '.MuiTabs-scroller': {
    overflow: 'visible!important',
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

const StyledTab = styled(Tab)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  minHeight: 'unset',
  lineHeight: 1.6,
  alignItems: 'start',
  zIndex: 2,
  fontWeight: 400,
  '&.Mui-selected': {
    color: '#fff',
    '.MuiTabs-indicator': {
      zIndex: '-1!important',
    },
  },
  // Shockwave effect for searched location
  '&.btn-shockwave': {
    overflow: 'visible!important',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      borderRadius: '10px',
      animation: 'shockwave 1s .65s ease-out infinite',
    },
  },

  '@keyframes shockwave': {
    '0%': {
      transform: 'scale(1)',
      boxShadow: '0 0 2px rgba(0,0,0,0.15), inset 0 0 1px rgba(0,0,0,0.15)',
    },
    '95%': {
      boxShadow: '0 0 50px rgba(0,0,0,0), inset 0 0 30px rgba(0,0,0,0)',
    },
    '100%': {
      transform: 'scale(2.25)',
    },
  },
})

const StoreTabs = ({ filters, staticData }) => {
  const [tabsInitialized, setTabsInitialized] = useState(false)
  const [selectedTab, setSelectedTab] = useState(0)
  const [defaultStoreIndex, setDefaultStoreIndex] = useState()
  const { data, isLoading } = useSortedStores()
  const searchedLocation = useStoreLocatorContext(state => state.searchedLocation)
  const { state: locationState } = getCurrentLocation()

  const handleChange = (e, newTab) => {
    setDefaultStoreIndex(null)
    setSelectedTab(newTab)
  }

  const storesData = data || staticData
  const [_, market] = storesData?.[selectedTab] || []

  // Tab defaults to the US state of the user's current location
  useEffect(() => {
    if (tabsInitialized) return
    const stateIndex = storesData.findIndex(([state]) => state === locationState)
    if (stateIndex >= 0) {
      setSelectedTab(stateIndex)
      setDefaultStoreIndex(stateIndex)
    }
    setTabsInitialized(true)
  }, [tabsInitialized, storesData, locationState])

  // Tab adjusts automatically when the user searches a location in a new US state
  useEffect(() => {
    const stateIndex = storesData.findIndex(([state]) => state === searchedLocation?.state)
    if (stateIndex >= 0) {
      setSelectedTab(stateIndex)
      setDefaultStoreIndex(stateIndex)
    }
  }, [searchedLocation, storesData])

  if (isLoading && !storesData) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 6 }}>
        <CircularProgress size="3.6rem" />
      </Box>
    )
  }

  return (
    <>
      {!!data && (
        <MobileStoreSelector
          data={data}
          selectedTab={selectedTab}
          defaultStoreIndex={defaultStoreIndex}
          setSelectedTab={setSelectedTab}
        />
      )}
      <StoreFilters filters={filters} />
      <Typography sx={{ textTransform: 'capitalize', fontWeight: 600 }} variant="h2">
        Store List
      </Typography>
      <Box sx={{ flexGrow: 1, display: 'flex', pt: 2 }}>
        <Tabs
          orientation="vertical"
          TabIndicatorProps={{
            sx: { width: '100%', zIndex: 1, borderLeft: t => `solid 6px ${t.palette.warning.main}` },
          }}
          value={selectedTab}
          onChange={handleChange}
          aria-label="Stores grouped by State, Area and City"
        >
          {storesData?.map(([stateName], index) => (
            <StyledTab
              // className={selectedTab === index && defaultStoreIndex === index && 'btn-shockwave'}
              label={stateName}
              icon={selectedTab === index ? <ChevronRight /> : null}
              iconPosition="end"
              key={`${toID(stateName)}-tab`}
              id={`storelocator-tab-${index}`}
              aria-controls={`storelocator-tabpanel-${index}`}
            />
          ))}
        </Tabs>
        {storesData?.map(([stateName], index) => (
          <TabPanel
            filters={filters}
            key={`${toID(stateName)}-panel`}
            selectedTab={selectedTab}
            index={index}
            market={market}
            state={stateName}
          />
        ))}
      </Box>
    </>
  )
}

StoreTabs.propTypes = {
  filters: PropTypes.object.isRequired,
  staticData: PropTypes.array.isRequired,
}

const query = graphql`
  query AllStoresQuery {
    allStrapiStore(filter: { strapi_id: { ne: 0 } }) {
      edges {
        node {
          id
          State
          City
          Address1
          PhoneNumber
          StoreNumber
          Zip
          StoreName
          StoreType
          Title
        }
      }
    }
  }
`

export default StoreTabs
